<template>
  <div>
    <div class="ma-4">
      ⚡️ АДВЕНТ-МАГАЗИН БУДЕТ ДОСТУПЕН ДЛЯ ПОКУПКИ ПОДАРКОВ С 22 ПО 25 ДЕКАБРЯ.
      ⚡️
    </div>

    <div class="ma-4">
      <div><strong>🛍 ОТКРЫТИЕ ПРОДАЖ 🛍</strong></div>
      <div>
        🗓 22 декабря, в 11:00 (GMT +3) на ваш счет в магазине будут начислены
        баллы для покупок.
      </div>
      <div>
        ☝️ Обратите внимание, что баллы, которые вы получите за выполнение
        задания 22 декабря, не начислятся в магазине, даже если вы выполните
        задание до 11:00. Они придут на следующий день (то есть, в субботу), в
        10:00.
      </div>
      <div>
        По творческому заданию выходного дня баллы будут начислены в
        понедельник, в 10:00.
      </div>
    </div>
    <div class="ma-4">
      <div><strong>🔥 ВОЗМОЖНОСТИ 🔥</strong></div>
      <div>
        ✅ Некоторые позиции в магазине вы можете приобрести в двойном (а где-то
        и в тройном) экземпляре. Лимит по покупке вы увидите при попытке
        добавить конкретный товар в корзину.
      </div>
      <div>
        ✅ Вы можете отменять выбор товаров, если необходимо, и выбирать другие.
      </div>
    </div>
    <div class="ma-4">
      <div><strong>⚠️ ВАЖНО! ЗАКРЫТИЕ МАГАЗИНА⚠️</strong></div>
      <div>
        Ваш окончательный выбор должен быть зафиксирован
        <strong>до 23:59 25 декабря</strong>. В 00:00 магазин завершит свою
        работу и ваши заказы отправятся на обработку.
      </div>
      <div>Во всех случаях время указано по Москве.</div>
    </div>
    <div class="ma-4">
      <div><strong>🚚 ОТПРАВКА / ПОЛУЧЕНИЕ ПОДАРКОВ 🚚</strong></div>
      <div>
        Для получения подарков вам необходимо заполнить форму по ссылке:
        <a href="https://hr.otr.ru/giftform">https://hr.otr.ru/giftform</a>
      </div>
      <div>
        Заполнить форму нужно <strong>до 25 декабря</strong> включительно.
      </div>
    </div>
    <div class="ma-4">
      <strong>‼️ Обратите внимание:</strong> если вы проживаете в городе, где
      есть филиал ОТР, то подарки будут отправлены на адрес филиала, даже если
      вы запросите доставку на домашний адрес. Это сделано с целью оптимизации
      логистических расходов.
    </div>
    <div class="ma-4">
      🏠 Если вы проживаете в городе, где нет филиала, то доставка будет
      организована на ваш домашний адрес.
    </div>
    <div class="ma-4">
      🎁 В Москве подарки можно будет получить
      <strong> 26, 27 или 28 декабря</strong>. Или запросить доставку курьером
      через форму:
      <a href="https://hr.otr.ru/giftform">https://hr.otr.ru/giftform</a>
      (только в пределах МКАД). Доставка будет осуществлена уже после Нового
      года в связи с высокой загрузкой наших курьеров из-за развоза подарков
      партнерам и заказчикам.
    </div>
    <div class="ma-4">
      🙏 Просим вас помнить, что количество подарков ограничено! 🙏
    </div>
  </div>
</template>

<script>
export default {
  name: "OTRShopRules",
};
</script>
