<template>
  <div>
    <v-row>
      <v-col cols="12"
        ><v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header expand-icon="mdi-menu-down">
              <div class="text-h5 d-flex justify-center">
                Ваш баланс {{ formatPrice(userBalance) }}
              </div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div
                v-for="item in balanceRecords"
                :key="`balance-record-${item._id}`"
                class="d-flex"
              >
                {{ item.description }}<v-spacer></v-spacer>
                <div
                  class="text-h5"
                  :class="{
                    'green--text': item.amount >= 0,
                    'red--text': item.amount < 0,
                  }"
                >
                  {{ formatPrice(item.amount) }}
                </div>
              </div>
              <div v-if="spent > 0" class="d-flex">
                На товары потрачено<v-spacer></v-spacer>
                <div class="text-h5 red--text">{{ formatPrice(spent) }}</div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="text-h5 d-flex justify-center">Правила</div>
              <template v-slot:actions>
                <v-icon color="primary"> $expand </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <rules />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
    <div class="d-flex align-center">
      <v-select
        label="Фильтр"
        :items="filterOptions"
        item-value="id"
        item-text="label"
        v-model="filter"
      />
      <v-spacer />
      <v-btn
        :disabled="userBalance == null || userBalance < 0"
        color="primary"
        @click="onTransferDialogOpen"
        >Трансфер баланса</v-btn
      >
    </div>
    <v-dialog v-model="dialogTransfer" :max-width="600">
      <v-card :elevation="4">
        <v-card-title> Перевод баланса пользователю </v-card-title>
        <v-card-text>
          <v-autocomplete
            :items="displayUsers"
            v-model="transferUser"
            item-value="id"
            item-text="name"
            return-object
            clearable
          />
          <v-text-field
            :value="transferAmount"
            type="number"
            @input="onChangeAmount"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="onTransferDialogCancel"
            >Отмена</v-btn
          >
          <v-spacer />
          <v-btn
            color="primary"
            :disabled="
              !(
                transferAmount <= userBalance &&
                transferAmount > 0 &&
                transferAmount != null &&
                transferUser
              )
            "
            @click="onTransferConfirmDialogOpen"
            text
            >Отправить</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTransferConfirm" :max-width="600">
      <v-card :elevation="4" v-if="transferUser">
        <v-card-title> Подтверждение перевода </v-card-title>
        <v-card-text>
          Вы действительно хотите перевести
          <strong>₽{{ transferAmount }}</strong> пользователю
          <strong>{{ transferUser.name }}</strong
          >?
        </v-card-text>
        <v-card-actions>
          <v-btn color="secondary" text @click="onTransferConfirmDialogCancel"
            >Отмена</v-btn
          >
          <v-spacer />
          <v-btn color="primary" text @click="onTransfer"
            >Прощай, деньги!</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-row>
      <v-col
        :cols="12"
        v-for="product in displayProducts"
        xl="4"
        lg="4"
        md="6"
        sm="6"
        xs="12"
        :key="`product-item-${product._id}`"
      >
        <v-card
          :elevation="4"
          :style="{
            ...(productPurchasesByProductIDs[product._id]
              ? {
                  backgroundColor: '#FAD099',
                }
              : {}),
          }"
        >
          <div
            style="width: 100%; position: relative; cursor: pointer"
            @click="
              selectedZoomImageIndex = 0;
              zoomImages = product.params.images;
              isImagesModalOpen = true;
            "
          >
            <v-img
              v-if="product.image"
              :src="product.image && product.image.replace(/\s/gi, '')"
              :aspect-ratio="1"
              cover
            />
            <div
              style="
                width: 100%;
                position: absolute;
                bottom: 0;
                background-color: rgba(0, 0, 0, 0.2);
              "
            >
              <v-card-title
                class="d-flex flex-column align-start"
                style="
                  word-break: break-word;
                  color: white;
                  text-shadow: 2px 1px 0px #000000;
                "
              >
                <div>{{ product.title }}</div>
                <div class="mt-1">₽ {{ product.price }}</div>
              </v-card-title>
            </div>
          </div>

          <v-card-text v-if="false">
            <div>
              {{ product.description }}
            </div>
            <div v-if="product.params.specs">
              <em>{{ product.params.specs }}</em>
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="d-flex align-center justify-center">
              <v-tooltip bottom color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    @click="onShowProductInformation(product)"
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon color="primary">mdi-information</v-icon>
                  </v-btn>
                </template>
                <span>Подробнее о подарке</span>
              </v-tooltip>

              <span v-if="product.remaining > 0">
                <strong>Осталось</strong>
                {{ product.remaining }} шт.
              </span>
              <span v-else>
                <strong>Этот подарок разобрали</strong>
              </span>
            </div>
            <v-spacer />
            <template>
              <v-btn
                v-if="
                  productPurchasesByProductIDs[product._id] == null &&
                  product.price <= userBalance &&
                  product.remaining > 0
                "
                text
                color="red"
                :loading="loading"
                @click="onProductQuantityUpdate(product, 1)"
                >Приобрести</v-btn
              >
              <template
                v-if="productPurchasesByProductIDs[product._id] != null"
              >
                <v-btn
                  icon
                  v-if="productPurchasesByProductIDs[product._id].quantity > 0"
                  @click="
                    onProductQuantityUpdate(
                      product,
                      productPurchasesByProductIDs[product._id].quantity - 1
                    )
                  "
                  class="mr-1"
                >
                  <v-icon color="primary">mdi-minus</v-icon>
                </v-btn>
                <span class="pl-2 pr-2"
                  ><strong>{{
                    productPurchasesByProductIDs[product._id].quantity
                  }}</strong></span
                >
                <v-btn
                  :disabled="
                    !(
                      product.remaining > 0 &&
                      productPurchasesByProductIDs[product._id] != null &&
                      product.price <= userBalance &&
                      ((product.maxPurchaseQuantity != null &&
                        productPurchasesByProductIDs[product._id].quantity <
                          product.maxPurchaseQuantity) ||
                        (product.maxPurchaseQuantity == null &&
                          productPurchasesByProductIDs[product._id].quantity >
                            0))
                    )
                  "
                  icon
                  @click="
                    onProductQuantityUpdate(
                      product,
                      productPurchasesByProductIDs[product._id].quantity + 1
                    )
                  "
                  class="mr-1"
                >
                  <v-icon color="primary">mdi-plus</v-icon>
                </v-btn>
              </template>
            </template>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="productInfoDialog" :max-width="600">
      <v-card :elevation="4" v-if="selectedProduct">
        <v-card-title>
          {{ selectedProduct.title }}
        </v-card-title>
        <v-card-text>
          <div>
            {{ selectedProduct.description }}
          </div>
          <div class="mt-2" v-if="selectedProduct.params.specs">
            <em>{{ selectedProduct.params.specs }}</em>
          </div>
          <div class="mt-4">
            Можно приобрести: {{ selectedProduct.maxPurchaseQuantity }}
          </div>
          <div
            class="mt-2"
            v-if="productPurchasesByProductIDs[selectedProduct._id]"
          >
            Вы приобрели:
            {{ productPurchasesByProductIDs[selectedProduct._id].quantity }}
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex flex-column" v-if="false">
      <v-sheet
        v-for="product in displayProducts"
        :key="`product-card-${product._id}`"
        class="ma-4"
      >
        <div class="d-flex">
          <v-img
            v-if="product.image"
            :src="product.image && product.image.replace(/\s/gi, '')"
            style="
              max-width: 300px;
              display: flex;
              text-align: center;
              cursor: pointer;
              height: 100%;
            "
            class="align-self-stretch justify-self-stretch d-none d-md-block"
            cover
            @click="
              selectedZoomImageIndex = 0;
              zoomImages = product.params.images;
              isImagesModalOpen = true;
            "
            ><div
              v-if="
                product.params &&
                product.params.images &&
                product.params.images.length
              "
              class="pa-4"
              style="color: white; font-size: 48px"
            >
              <span
                v-for="index in product.params.images.length"
                :key="`img-dot-dot-${index}`"
                class="mx-2"
                >.</span
              >
            </div></v-img
          >
          <v-card
            :style="{
              ...(productPurchasesByProductIDs[product._id]
                ? {
                    backgroundColor: '#FAD099',
                  }
                : {}),
            }"
            class="align-self-stretch justify-self-stretch"
          >
            <v-img
              height="400"
              :src="product.image && product.image.replace(/\s/gi, '')"
              class="align-self-stretch justify-self-stretch d-md-none"
              cover
            ></v-img>
            <v-card-title class="align-start" style="word-break: break-word">{{
              product.title
            }}</v-card-title>
            <v-card-text>
              <div class="mb-2">
                {{ product.description }}
              </div>
              <div class="mb-2" v-if="product.params.specs">
                <em>{{ product.params.specs }}</em>
              </div>
              <v-divider></v-divider>
              <div class="d-flex mt-2">
                <strong>Цена</strong>
                <v-spacer></v-spacer>
                <div class="text-h5">{{ formatPrice(product.price) }}</div>
              </div>
              <div class="d-flex mt-2" v-if="product.remaining > 0">
                <strong>Осталось</strong>
                <v-spacer></v-spacer>
                {{ product.remaining }} шт.
              </div>
              <div class="d-flex mt-2" v-else>
                <strong>Этот подарок уже разобрали</strong>
              </div>
              <div
                class="d-flex mt-2"
                v-if="
                  productPurchasesByProductIDs[product._id] &&
                  productPurchasesByProductIDs[product._id].quantity
                "
              >
                <strong>Вы приобрели</strong>
                <v-spacer></v-spacer>
                <strong
                  >{{
                    productPurchasesByProductIDs[product._id].quantity
                  }}
                  шт.</strong
                >
              </div>
            </v-card-text>
            <v-card-actions>
              <template
                v-if="product.price <= userBalance && product.remaining > 0"
              >
                <v-btn
                  v-if="
                    !productPurchasesByProductIDs[product._id] ||
                    productPurchasesByProductIDs[product._id].quantity === 0
                  "
                  text
                  color="red"
                  :loading="loading"
                  @click="onProductQuantityUpdate(product, 1)"
                  >Приобрести</v-btn
                >
                <v-btn
                  v-if="
                    productPurchasesByProductIDs[product._id] &&
                    productPurchasesByProductIDs[product._id].quantity < 3
                  "
                  text
                  color="red"
                  :loading="loading"
                  @click="
                    onProductQuantityUpdate(
                      product,
                      productPurchasesByProductIDs[product._id].quantity + 1
                    )
                  "
                  >Приобрести еще 1 шт.</v-btn
                >
              </template>
              <v-spacer></v-spacer>
              <v-btn
                text
                color="secondary"
                @click="onProductQuantityUpdate(product, 0)"
                :loading="loading"
                v-if="
                  productPurchasesByProductIDs[product._id] &&
                  productPurchasesByProductIDs[product._id].quantity
                "
                >Отменить</v-btn
              >
            </v-card-actions>
          </v-card>
        </div>
      </v-sheet>
    </div>
    <v-dialog fullscreen :value="isImagesModalOpen">
      <div style="width: 100%; height: 100vh; position: relative">
        <div
          style="
            top: 10px;
            right: 10px;
            position: absolute;
            color: white;
            z-index: 100;
          "
        >
          <v-btn icon color="white">
            <v-icon @click="isImagesModalOpen = false">mdi-close</v-icon>
          </v-btn>
        </div>
        <v-carousel
          :cycle="false"
          style="width: 100%; background-color: #000d"
          height="100vh"
          :show-arrows="true"
          :continuous="true"
          v-model="selectedZoomImageIndex"
        >
          <v-carousel-item
            v-for="(item, index) of zoomImages"
            style="height: 100%; width: 100%"
            :key="index"
          >
            <v-img
              :src="item && item.replace(/\s/gi, '')"
              style="width: 100%; height: 100%"
              :contain="true"
            />
          </v-carousel-item>
        </v-carousel>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import Rules from "./Rules.vue";
import Vue from "vue";

export default {
  name: "OTR2023Shop",
  components: {
    Rules,
  },
  data() {
    return {
      loading: true,
      products: [],
      balanceRecords: [],
      productPurchases: [],
      roubleNumberFormat: Intl.NumberFormat("ru-RU"),
      apiMessage: null,
      isImagesModalOpen: false,
      zoomImages: null,
      selectedZoomImageIndex: 0,
      selectedProduct: null,
      productInfoDialog: false,

      transferAmount: 0,
      transferUser: null,
      dialogTransfer: false,
      dialogTransferConfirm: false,

      users: [],

      filter: "all",
      filterOptions: [
        {
          id: "all",
          label: "Все товары",
        },
        {
          id: "remaining",
          label: "Доступно для продажи",
        },
        {
          id: "affordable",
          label: "На что хватает баланса",
        },
        {
          id: "my-purchases",
          label: "Мои покупки",
        },
      ],
    };
  },
  created() {
    this.filter = window.localStorage.getItem("otr-2024-filter") || "all";
    this.fetchProducts();
    this.fetchUsers();
    this.fetchBalanceRecords();
    this.fetchProductPurchases();
  },
  computed: {
    ...mapGetters({
      user: "event/user",
    }),
    productPurchasesByProductIDs() {
      return _.keyBy(this.productPurchases, "product");
    },
    income() {
      return _.reduce(
        this.balanceRecords,
        (total, { amount }) => {
          return total + amount;
        },
        0
      );
    },
    spent() {
      return _.reduce(
        this.productPurchases,
        (total, { amount }) => {
          return total + amount;
        },
        0
      );
    },
    userBalance() {
      return this.income - this.spent;
    },
    userBalanceStr() {
      return this.roubleNumberFormat.format(this.userBalance);
    },
    userId() {
      return _.get(this.user, "id");
    },
    displayUsers() {
      return _.filter(this.users, (item) => {
        return item._id !== this.userId;
      }).map((item) => {
        return {
          id: item._id,
          name: _.get(item, "profile.name"),
        };
      });
    },
    displayProducts() {
      const items = _.map(this.products, (item) => {
        return {
          ...item,
          remaining: Math.max(0, item.quantity - item.purchased),
        };
      });
      switch (this.filter) {
        case "all":
          return items;
        case "remaining":
          return items.filter((item) => item.remaining > 0);
        case "affordable":
          return items.filter((item) => item.price <= this.userBalance);
        case "my-purchases":
          return items.filter((item) => {
            return this.productPurchasesByProductIDs[item._id];
          });
        default:
          return items;
      }
    },
  },
  watch: {
    filter(val) {
      window.localStorage.setItem("otr-2024-filter", val);
    },
  },
  methods: {
    ...mapActions({
      getProducts: "event/getShopProducts",
      getBalanceRecords: "event/getShopBalanceRecords",
      getProductPurchases: "event/getShopProductPurchases",
      postProductPurchase: "event/postShopProductPurchase",
      getShopUsers: "event/getShopUsers",
      postShopBalanceTransfer: "event/postShopBalanceTransfer",
    }),
    onChangeAmount(value) {
      const parsedVal = Number.parseInt(value);
      if (
        !Number.isNaN(parsedVal) &&
        parsedVal >= 0 &&
        parsedVal <= this.userBalance
      ) {
        this.transferAmount = parsedVal;
      }
    },
    onTransferDialogOpen() {
      // this.transferUser = null;
      // this.transferAmount = 0;
      this.dialogTransfer = true;
    },
    onTransferDialogCancel() {
      this.dialogTransfer = false;
    },
    onTransferConfirmDialogOpen() {
      this.dialogTransferConfirm = true;
    },
    onTransferConfirmDialogCancel() {
      this.dialogTransferConfirm = false;
    },
    async onTransfer() {
      if (
        this.transferUser != null &&
        this.transferAmount > 0 &&
        this.transferAmount <= this.userBalance
      ) {
        const { data } = await this.postShopBalanceTransfer({
          to: this.transferUser.id,
          amount: this.transferAmount,
        });
        this.dialogTransferConfirm = false;
        this.dialogTransfer = false;
        this.transferAmount = 0;
        this.transferUser = null;
        this.fetchBalanceRecords();
        if (data && data.message) {
          Vue.$toast.open({
            type: "info",
            message: data.message,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }
      }
    },
    onShowProductInformation(product) {
      this.selectedProduct = product;
      this.productInfoDialog = true;
    },
    formatPrice(num) {
      return `${this.roubleNumberFormat.format(num).replace(",", ".")}`;
    },
    async fetchProducts() {
      try {
        this.loading = true;
        const { data } = await this.getProducts();
        this.products = data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    async fetchUsers() {
      try {
        const { data } = await this.getShopUsers();
        this.users = data;
        console.log(data);
      } catch (err) {
        console.error(err);
      }
    },
    async fetchBalanceRecords() {
      try {
        const { data } = await this.getBalanceRecords();
        this.balanceRecords = data;
      } catch (err) {
        console.error(err);
      }
    },
    async fetchProductPurchases() {
      try {
        const { data } = await this.getProductPurchases();
        this.productPurchases = data;
        console.log(data);
      } catch (err) {
        console.error(err);
      }
    },
    async onProductQuantityUpdate(product, newAmount) {
      try {
        this.loading = true;
        const numNewAmount = Number.parseInt(newAmount);
        if (Number.isNaN(numNewAmount)) return;

        const payload = {
          product: product._id,
          quantity: numNewAmount,
        };
        const { data } = await this.postProductPurchase(payload);
        this.apiMessage = data && data.message;
        if (this.apiMessage) {
          Vue.$toast.open({
            type: "info",
            message: this.apiMessage,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }

        await this.fetchProducts();
        await this.fetchBalanceRecords();
        await this.fetchProductPurchases();
      } catch (err) {
        this.apiMessage = err && err.message;
        if (this.apiMessage) {
          Vue.$toast.open({
            type: "error",
            message: this.apiMessage,
            position: "top",
            duration: 5000,
            dismissible: true,
          });
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
